//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  import screenFilter from '@/views/components/ishop/screenFilter'
  import restaurantList from '@/views/components/ishop/shopList'
  import myCart from '@/views/components/ishop/dialogs/myCart'
  import { miceService } from '@/service/miceService'
  import {
    Actionsheet
  } from 'mint-ui';
  export default {
    props:['cartShops'],
    name: 'step2CustomShop',
    data() {
      return {
        Ifshow: false,
        popupRecommend: false,
        headValue: {
          name: '自定义餐厅',
          haveBack: true,
          haveRule: true,
        },
        actions: [{
          name: "外卖",
          method: this.closes
        }, {
          name: '茶歇',
          method: this.closes
        }],
        cart: {
          take: true,
          select: 1,
          sum: 1,
          "cityId": '',
          "hospitalId": '',
          itemType: 7,
          tag: '外卖/茶歇',
          shops: [{
            status: "true",
            value: '外卖',
            mealType: "1",
            name: '',
            "recommendType": 2,
            "rowId": 0,
            "shopId": '',
            "imgThumbnail": "",
            "priceAvgVal": 0,
            "regionVal": "",
            "categoryVal": "",
            "tel": "",
            "address": "",
            "checked": true
          }]
        },
        typeNum:this.$store.state.ishop.maxQty7Type2
        // cartShops: []
      }
    },
    created() {
      this.Bus.$on('openPropu', openPropu => {
        this.popupRecommend = openPropu
      });
      this.Bus.$emit("recommendShop",this.cart.shops)
      this.queryMyShops()
    },
    methods: {
        select(index) {
            this.Ifshow = !this.Ifshow
            this.cart.shops[index].status = "false"
        },
      deleteGroup: function(index) {
        if (this.cart.shops.length > 1) {
          this.cart.shops.splice(index, 1);
          this.cart.select--
            this.cart.sum++
        }
      },
      listShops() {
        let newShops2 = []
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if(this.cart.shops[i].name !="" && this.cart.shops[i].tel !="" && this.cart.shops[i].address != ""){
            newShops2.push(this.cart.shops[i])
          }
        }
        if(newShops2.length!=this.cart.shops.length){
            this.messageBox({
              message: `存在未填写项，请填写完毕再保存`,
              closeOnClickModal: false,
              confirmButtonText: '确定'
            })
          }else{
            this.toast({
              message: '保存成功',
              duration: 2000,
            });
          }
        this.Bus.$emit("recommendShop",newShops2)
      },
      addGrounp() {
        if (this.cart.shops.length < this.$store.state.ishop.maxQty5Type) {
          console.log(this.cart.shops.length)
          let item = {
            shopId: null,
            RecommendType: 2,
            name: "",
            address: "",
            "rowId": 0,
            "imgThumbnail": "",
            "priceAvgVal": 0,
            "regionVal": "",
            "categoryVal": "",
            "tel": "",
            "checked": false,
          }
          this.cart.shops.push(item)
          this.cart.select++
            this.cart.sum--
        } else {
          var _this=this;
          this.toast({
            message: '最多可推荐'+_this.typeNum+'家餐厅',
            duration: 5000,
          });
        }
      },
      queryMyShops: async function() {
        let params = {
          "itemType": 5,
          // "cityId": 0,
          "hospitalId": sessionStorage.getItem('hospitalId'),
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
        }
        const res = await miceService.queryMyShops(params)
        if (res.data.customed.length > 0) {
          this.cart.shops = res.data.customed
          this.Bus.$emit("recommendShop",this.cart.shops)
          console.log(this.cart.shops)
          this.cart.select = res.data.customed.length
          this.cart.sum = 2 - res.data.customed.length
        }
      },
      changePropu() {
        var changePopup = true
        this.Bus.$emit('changePopup', changePopup);
      },
      postMyshops: async function() {
        console.log(this.cartShops.shops,this.cart.shops)
        let newShops = []
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if(this.cart.shops[i].name !="" && this.cart.shops[i].tel !="" && this.cart.shops[i].address != ""){
            newShops.push(this.cart.shops[i])
          }
        }
        // console.log(this.cartShops.shops,newShops)
        this.cartShops.shops.unshift.apply(this.cartShops.shops, newShops)
        // console.log(this.cartShops.shops)
				//过滤已保存的餐厅 TODO
        let nowShop = this.cartShops.shops;
				nowShop = nowShop.filter((ele)=>{
					return !ele.recommendId
				})
				if(nowShop.length < 1){
					this.toast('您尚未添加新餐厅');
					this.isSubmitData = false;
					return false;
				}
        let params = {
          cityId: sessionStorage.getItem('pingRegionId'),
          hospitalId: sessionStorage.getItem('hospitalId'),
          itemType: this.cart.itemType,
          shops: nowShop,
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
        }
        let pass = localStorage.getItem('channel') || sessionStorage.getItem('channel')
        const res = await miceService.postMyShops(params)
        if (res.status) {
          if(pass=='app'){
            this.messageBox({
              message: this.$store.state.ishop.meesageRule,
              confirmButtonText: '确定'
            }).then(action => {
              this.$router.push('/myShops')
            })
          }else{
            this.$router.push('/myShops')
          }
        }
      },
      submitData: function() {
        if(this.isSubmitData){
          this.toast({
            message: '正在提交ing..请勿重复操作',
            duration: 2000,
          });
          return;
        }
        console.log(this.cart.shops);
        let that = this;
        let count = true
        let mealTypeSame = "";
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if(mealTypeSame == this.cart.shops[i].mealType){
            this.toast({
              message: '外卖/茶歇最多只能各一个',
              duration: 3000,
            });
            return;
          }else{
            mealTypeSame = this.cart.shops[i].mealType;
          }
          if (this.cart.shops[i].name){
              count = count && true;
          }
          else{
              count = count && false;
              break;
          }
        }
        if (count){
          this.isSubmitData = true
            that.postMyShops();
        }
        else{
            this.messageBox({
              message: `存在未填写项，确认提交`,
              showCancelButton: true,
              cancelButtonText: "取消",
              confirmButtonText: '确定'
            }).then(action => {
              // console.log(action)
              if (action == "confirm") {
                this.isSubmitData =true
                that.postMyShops();
              }
            })
        }
      },
      postMyShops: async function() {
        let nowShop=[];
        for (var i = 0, length = this.cart.shops.length; i < length; i++) {
          if (this.cart.shops[i].name!='') {
            nowShop.push(this.cart.shops[i])
          }
        }
        this.cart.shops=nowShop;
        //过滤已保存的餐厅 TODO
				nowShop = nowShop.filter((ele)=>{
					return !ele.recommendId
				})
				if(nowShop.length < 1){
					this.toast('您尚未添加新餐厅');
					this.isSubmitData = false;
					return false;
				}
        let params = {
          hospitalId: sessionStorage.getItem("hospitalId"),
          cityId: sessionStorage.getItem('pingRegionId'),
          itemType: 7,
          shops: nowShop,
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
        }
        // console.log(params);
        const res = await miceService.postMyShops(params)
        if(res){this.isSubmitData=false}
        if (res.status) {
          this.$router.push('/mytakeaway');
        }
        },
    },
    components: {
      normalHead,
      myCart
    }
  }
